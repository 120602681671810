define("discourse/plugins/amazon-affiliate/discourse/initializers/amazon-affiliate", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DOMAIN_REGEXP = /(amazon|amzn)\.(com|ca|de|it|es|fr|co\.jp|co\.uk|cn|in|com\.br|com\.mx)\//i;
  function initPlugin(api) {
    if (!Discourse.SiteSettings.amazon_affiliate_enabled) return;
    api.decorateCooked($el => {
      const affID = Discourse.SiteSettings.amazon_affiliate_id;
      const links = $el.find("a");
      let count = 0;
      links.each(function (idx, el) {
        const href = $(this).prop("href");
        if (href.match(DOMAIN_REGEXP)) {
          const url = new URL(href);
          const text = $(this).text();
          url.searchParams.set("tag", affID);
          $(this).prop("href", url.toString());
          if (text === href) {
            $(this).text(url.toString());
          }
          count++;
        }
      });
      if (count) {
        const text = Discourse.SiteSettings.amazon_affiliate_disclosure;
        const disclosure = `<div class="a-disc">${text}</div>`;
        $el.append(disclosure);
      }
    }, {
      onlyStream: true,
      id: "amazon-affiliate"
    });
  }
  var _default = _exports.default = {
    name: "amazon-affiliate",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", initPlugin);
    }
  };
});